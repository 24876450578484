<template>
  <div class="mode-center">

    <div class="pt63"></div>

    <div class="pblock">
      <div class="grid grid-cols-12 gap-10 mx900:grid-cols-1">
        <div class="text text-grey-light" :class="[!data.media_type ? 'mn900:col-span-12' : 'mn900:col-span-7']">
          <div class="hdr1 pbhdr1">
            {{data.title}}
          </div>
          <div class="wysiwyg text text-bread maxw560" v-html="data.text">
          </div>
        </div>
        <div class="mn900:col-span-5 text-right relative mx900:text-center" v-if="data.media_type">
          <img class="inline-block" :src="data.image.file" />
          <!--  
          <div class="pos-g bg-cover" :style="{ backgroundImage: 'url(' + data.img + ')' }"></div>
          -->
        </div>
      </div>
    </div>

    <!--  
    -->
    <div class="vspace1"></div>
    <div class="vspace1"></div>

    <div class="stickywrap pointer-events-none" id="step_next_button">
      <div class="pos-g grad pointer-events-none"></div>
      <div class="relative mode-center pointer-events-auto">
        <div class="grid grid-cols-2 gap-10 gap-y-4 mx720:grid-cols-1">
          <btn_default :obj="{'text':'Lees de inzichten', 'color': 'grey', 'mode': 'route', 'to': 'inzichten'}" />
          <btn_default :obj="{'text':'Naar het stappenplan', 'icon': 'arrow-right', 'mode': 'route', 'to': 'stappenplan'}" class="mx720:order-first" />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import btn_default from '@/components/ui/btn_default'

var lv = {}
lv.thisVue = null

export default {
  name: 'home',
  components: {
    btn_default
  },
  data() {
    return {
      data:{
        image: {}
      },
    }
  },
  mounted() {
    lv.thisVue = this

    lv.dataTask = "home_fetch"
    lv.dataEvent = "data_home_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      lv.thisVue.populate()
    })
  },
  methods: {
    populate(){
      this.data = globals.api_data[lv.dataTask].item
    }
  }
}
</script>

<style>

</style>