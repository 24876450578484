<template>
  <div>
    <button 
      class="rounded text-center text text-btn text-dark transition duration-300 ease-in-out focus:outline-none"
      :class="[
        obj.wFull ? 'w-full' : '', 
        obj.small ? 'p-1' : 'py-5 px-4', 
        obj.wide ? 'px-16' : '', 
        !obj.color ? 'bg-malibu-500 hover:bg-malibu-600' : '',
        obj.color == 'grey' ? 'bg-grey-light hover:bg-malibu-300' : ''
      ]"
      @click.prevent="$emit('btn-click')"
      v-if="obj.mode == 'button'"
    >

      <span v-if="obj.text">
        {{obj.text}}
      </span>

      <span v-if="obj.icon == 'prev'">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
      </span>
      <span v-if="obj.icon == 'arrow-right'">
        <svg class="w-10 h-10 mx1280:hidden inline-block icon_nudge" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </span>

    </button>

    <router-link 
      :to="'/' + obj.to" 
      class="w-full rounded block py-5 px-4 text-center text text-btn text-dark cursor-pointer transition duration-300 ease-in-out"
      :class="[
        !obj.color ? 'bg-malibu-500 hover:bg-malibu-600' : '',
        obj.color == 'grey' ? 'bg-grey-light hover:bg-malibu-300' : ''
      ]"
      v-if="obj.mode == 'route'"
    >
      <span v-if="obj.text">
        {{obj.text}}
      </span>
      <span v-if="obj.icon == 'arrow-right'">
        <svg class="w-10 h-10 mx1280:hidden inline-block icon_nudge" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </span>
    </router-link>

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'

export default {
  name: "btn_default",
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style>

.icon_nudge{
  position: relative;
  top: -5px;
  margin-left: 6px;
}

</style>

