<template>

<div class="relative">

  <div class="question_wrap">

    <div class="text text-white text-big cursor-pointer navprev_nudge" @click="btnPrev">
      <div class="hover:text-soft-blue hover:border-soft-blue py-2 px-1 transition duration-300 ease-in-out flex items-center">
        <div class="inline-block mr-3">
          <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
        </div>
        Een stapje terug
      </div>
    </div>

    <div class="pt63 presp"></div>

    <div class="pblock">
      <div class="grid grid-cols-12 gap-10 mx900:grid-cols-1">
        <div class="text text-grey-light" :class="[!data.image ? 'mn900:col-span-12' : 'mn900:col-span-7']">
          <div class="hdr1 pbhdr1">
            {{data.title}}
          </div>
          <div class="wysiwyg text text-bread maxw560" v-html="data.text">
          </div>
        </div>
        <div class="mn900:col-span-5 text-right relative mx900:text-center" v-if="data.image">
          <img class="inline-block" :src="data.image.md" />
        </div>
      </div>

      <!--  
      -->
      <div class="vspace1"></div>
      <div class="vspace1"></div>

    </div>


    <div class="stickywrap pointer-events-none" id="step_next_button">
      <div class="pos-g grad pointer-events-none"></div>
      <div class="relative mode-center-alt pointer-events-auto">
        <div class="grid grid-cols-2 gap-10 mx480:grid-cols-1">
          <btn_default :obj="{'text': 'Opnieuw','wFull': true, 'mode': 'button'}" @btn-click="btnAgain" />
          <btn_default :obj="{'text':'Lees de inzichten', 'color': 'grey', 'mode': 'route', 'to': 'inzichten'}" />
        </div>
      </div>
    </div>

    


  </div>

</div>

</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import btn_default from '@/components/ui/btn_default'

var lv = {}
lv.thisVue = null

export default {
  name: 'kit_outro',
  components: {
    btn_default
  },
  data() {
    return {
      data:{
        image: {}
      },
    }
  },
  mounted() {
    lv.thisVue = this

    lv.dataTask = "kit_outro_fetch"
    lv.dataEvent = "data_kit_outro_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      lv.thisVue.populate()
    })
  },
  methods: {
    populate(){
      this.data = globals.api_data[lv.dataTask].item
    },
    btnPrev(){
      EventBus.$emit('kit_flow_prev')
    },
    btnAgain(){
      EventBus.$emit('kit_flow_restart')
    }
  }
}
</script>

<style>

</style>