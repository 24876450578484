<template>

  <div>

    <div class="text text-white text-big cursor-pointer navprev_nudge" @click="btnFeedbackClose">
      <div class="hover:text-soft-blue hover:border-soft-blue py-2 px-1 transition duration-300 ease-in-out flex items-center">
        <div class="inline-block mr-3">
          <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
        </div>
        Een stapje terug
      </div>
    </div>

    <div class="pt63 presp"></div>

    <div class="pblock text">
      <!-- 
      <div v-if="obj.feedback_image">
        <img class="inline-block mx900:block" :src="obj.feedback_image.sm" />
      </div>
      -->

      <div class="grid grid-cols-12 gap-10 mx900:grid-cols-1">
        <div class="text text-grey-light" :class="[!obj.feedback_image ? 'mn900:col-span-12' : 'mn900:col-span-7']">
          <div class="hdr1 pbhdr1">
            {{obj.title}}
          </div>
          <div class="wysiwyg text text-bread maxw560" v-html="answer_text">
          </div>
        </div>
        <div class="mn900:col-span-5 text-right relative mx900:text-center" v-if="obj.feedback_image">
          <img class="inline-block" :src="obj.feedback_image.sm" />
        </div>
      </div>

      <div class="vspace3"></div>

      <div v-if="feedback_1">
        <kit_feedback_node v-show="obj.feedback.feedback_1.item_1"
        :cat="1" :cat_title="title_category_1" :icon="icon_category_1" :obj="obj.feedback.feedback_1.item_1"  />
        <kit_feedback_node v-show="obj.feedback.feedback_1.item_2"
        :cat="2" :cat_title="title_category_2" :icon="icon_category_2" :obj="obj.feedback.feedback_1.item_2"  />
        <kit_feedback_node v-show="obj.feedback.feedback_1.item_3"
        :cat="3" :cat_title="title_category_3" :icon="icon_category_3" :obj="obj.feedback.feedback_1.item_3"  />
        <kit_feedback_node v-show="obj.feedback.feedback_1.item_4"
        :cat="4" :cat_title="title_category_4" :icon="icon_category_4" :obj="obj.feedback.feedback_1.item_4"  />
      </div>

      <div v-if="feedback_2">
        <kit_feedback_node v-show="obj.feedback.feedback_2.item_1"
        :cat="1" :cat_title="title_category_1" :icon="icon_category_1" :obj="obj.feedback.feedback_2.item_1"  />
        <kit_feedback_node v-show="obj.feedback.feedback_2.item_2"
        :cat="2" :cat_title="title_category_2" :icon="icon_category_2" :obj="obj.feedback.feedback_2.item_2"  />
        <kit_feedback_node v-show="obj.feedback.feedback_2.item_3"
        :cat="3" :cat_title="title_category_3" :icon="icon_category_3" :obj="obj.feedback.feedback_2.item_3"  />
        <kit_feedback_node v-show="obj.feedback.feedback_2.item_4"
        :cat="4" :cat_title="title_category_4" :icon="icon_category_4" :obj="obj.feedback.feedback_2.item_4"  />
      </div>

      <div v-if="feedback_3">
        <kit_feedback_node v-show="obj.feedback.feedback_3.item_1"
        :cat="1" :cat_title="title_category_1" :icon="icon_category_1" :obj="obj.feedback.feedback_3.item_1"  />
        <kit_feedback_node v-show="obj.feedback.feedback_3.item_2"
        :cat="2" :cat_title="title_category_2" :icon="icon_category_2" :obj="obj.feedback.feedback_3.item_2"  />
        <kit_feedback_node v-show="obj.feedback.feedback_3.item_3"
        :cat="3" :cat_title="title_category_3" :icon="icon_category_3" :obj="obj.feedback.feedback_3.item_3"  />
        <kit_feedback_node v-show="obj.feedback.feedback_3.item_4"
        :cat="4" :cat_title="title_category_4" :icon="icon_category_4" :obj="obj.feedback.feedback_3.item_4"  />
      </div>

      
    </div>

    
    <div class="stickywrap pointer-events-none" id="step_next_button">
      <div class="pos-g grad pointer-events-none"></div>
      <div class="relative h-full w-full text-center">
        <div class="relative inline-block pointer-events-auto">
          <btn_default :obj="{'text':'Volgende', 'icon': 'arrow-right', 'color': 'grey', 'mode': 'button', 'wide': true}" @btn-click="btnNext" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'

import btn_default from '@/components/ui/btn_default'
import kit_feedback_node from '@/components/kit_feedback_node'

var lv = {}
lv.thisVue = null

export default {
  name: 'kit_feedback',
  props: ['obj'],
  components: {
    btn_default,
    kit_feedback_node
  },
  data() {
    return {
      feedback_1: false,
      feedback_2: false,
      feedback_3: false,
      answer_text: null,
      title_category_1: null,
      title_category_2: null,
      title_category_3: null,
      title_category_4: null,
      icon_category_1: null,
      icon_category_2: null,
      icon_category_3: null,
      icon_category_4: null,
    }
  },
  mounted() {
    lv.thisVue = this

    if(globals.api_data['kit_intro_fetch']){
      this.populate()
    }
  },
  methods: {
    setup(e){

      this.answer_text = globals.answer_text_cur
      this.feedback_1 = false
      this.feedback_2 = false
      this.feedback_3 = false
      this['feedback_'+ e] = true

    },
    populate(){
      this.title_category_1 = globals.api_data['kit_intro_fetch'].categories.title_category_1
      this.title_category_2 = globals.api_data['kit_intro_fetch'].categories.title_category_2
      this.title_category_3 = globals.api_data['kit_intro_fetch'].categories.title_category_3
      this.title_category_4 = globals.api_data['kit_intro_fetch'].categories.title_category_4

      this.icon_category_1 = globals.api_data['kit_intro_fetch'].categories.icon_category_1.file
      this.icon_category_2 = globals.api_data['kit_intro_fetch'].categories.icon_category_2.file
      this.icon_category_3 = globals.api_data['kit_intro_fetch'].categories.icon_category_3.file
      this.icon_category_4 = globals.api_data['kit_intro_fetch'].categories.icon_category_4.file
    },
    btnFeedbackClose(){
      EventBus.$emit('kit_flow_feedback_close')
      EventBus.$emit('video_pause')
    },
    btnNext(dest){
      EventBus.$emit('kit_flow_next')
    }
  }
}

EventBus.$on('kit_flow_feedback_chosen', (e) => {
  lv.thisVue.setup(e)
})
EventBus.$on('kit_categories', (e) => {
  lv.thisVue.populate()
})


</script>

<style>


</style>