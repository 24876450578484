<template>

  <div class="mb-fb rounded"
    :class="[
      cat == 1 ? 'bg-brick text-grey-light' : '', 
      cat == 2 ? 'bg-pine text-grey-light' : '', 
      cat == 3 ? 'bg-cheese text-dark' : '', 
      cat == 4 ? 'bg-navy text-grey-light' : '', 
    ]"
    :id="'feedback_' + cat"
  >

    <div class="relative flex items-center pad-fb">
      <img class="inline-block iconimg mr-6 iconfeedback mx480:hidden" :src="icon" />
      <div class="hdrfeedback pt-2">
        {{cat_title}}
      </div>
      <div class="ml-auto">
        <svg class="w-16 h-16 mx640:w-12 mx640:h-12 mx480:w-10 mx480:h-10 inline-block icon_info"
          :style=" cat == 3 ? 'fill:#1d2026;' : 'fill:#f4f4f4;' "
          viewBox="0 0 16.933333 16.933333"><g transform="translate(0,-280.06669)"><path d="M 32 2 C 15.455147 2 2 15.455156 2 32 C 2 48.544882 15.455147 62 32 62 C 48.544852 62 62 48.544882 62 32 C 62 15.455156 48.544852 2 32 2 z M 32 6 C 46.383091 6 58 17.616932 58 32 C 58 46.383106 46.383091 57.998047 32 57.998047 C 17.616907 57.998047 6.0019531 46.383106 6.0019531 32 C 6.0019531 17.616932 17.616907 6 32 6 z M 31.970703 17.974609 A 2.0002 2.0002 0 0 0 30 19.996094 L 30 29.998047 L 19.998047 29.998047 A 2.0004851 2.0004851 0 1 0 19.998047 34 L 30 34 L 30 44.001953 A 2.0004923 2.0004923 0 1 0 34.001953 44.001953 L 34.001953 34 L 44.003906 34 A 2.0004851 2.0004851 0 1 0 44.003906 29.998047 L 34.001953 29.998047 L 34.001953 19.996094 A 2.0002 2.0002 0 0 0 31.970703 17.974609 z " transform="matrix(0.26458333,0,0,0.26458333,0,280.06669)"/></g></svg>
      </div>
      <div class="pos-g cursor-pointer" @click="feedback_toggle(cat)" @mouseover="feedback_over(cat)" @mouseleave="feedback_out(cat)"></div>
    </div>

    <div class="hidden feedback_content">
      <div class="pad-fb-content wrap-fb-content">

        <div class="grid gap-10 grid-cols-12 mx900:grid-cols-1" v-if="obj">
          <div class="col-span-7 mx900:col-span-1" :class="[!obj.media_type ? 'col-span-12' : '']">
            <div class="wysiwyg text-feedback" v-html="obj.text">
            </div>
          </div>
          <div class="col-span-5 mx900:col-span-1 text-right mx900:text-center relative" v-if="obj.media_type == 'image'">
            <img class="inline-block wrap-fb-content-media" :src="obj.image.sm" />
          </div>
          <div class="col-span-5 mx900:col-span-1 text-right relative" v-if="obj.media_type == 'youtube' || obj.media_type == 'vimeo'">
            <div class="w-full wrap-fb-content-media">

              <video_plyr :item="obj" :id="'feedback_' + obj.id" />

            </div>
          </div>
        </div>

      </div>
    </div>


  </div>

</template>

<script>
import { globals } from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import video_plyr from '@/components/video_plyr'

import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

var lv = {}
lv.thisVue = null

export default {
  name: 'kit_feedback_node',
  props: ['obj','cat','cat_title','icon','fid'],
  components: {
    video_plyr
  },
  data() {
    return {
      youtube_pre: globals.embed.youtube.pre,
      youtube_post: globals.embed.youtube.post,
      vimeo_pre: globals.embed.vimeo.pre,
      vimeo_post: globals.embed.vimeo.post,
    }
  },
  mounted() {
    lv.thisVue = this
   
    EventBus.$on('feedback_reset', (e) => {
      //console.log('--------------------------- feedback_reset')
      this.feedback_reset()
    })    


  },
  methods: {
    feedback_toggle(id){
      if(lv['open_' + id]){
        this.feedback_close(id)
        lv['open_' + id] = false
      }else{
        this.feedback_open(id)
        lv['open_' + id] = true
      }
    },
    feedback_open(id){
      lv.targ = '#feedback_' + id + ' .feedback_content'
      gsap.set(lv.targ,{display: 'block'})
      lv.targ = '#feedback_' + id + ' .icon_info'
      gsap.to(lv.targ,{duration:0.3, rotation:'45deg', ease:'back'})
      lv.desty = document.getElementById('feedback_' + id).offsetTop
      gsap.to(window, {duration: 0.5, scrollTo: {y: lv.desty, offsetY: 20}, ease:'power3.inOut'})
    },
    feedback_close(id){
      lv.targ = '#feedback_' + id + ' .feedback_content'
      gsap.set(lv.targ,{display: 'none'})
      lv.targ = '#feedback_' + id + ' .icon_info'
      gsap.to(lv.targ,{duration:0.3, rotation:'0deg', ease:'back'})

      EventBus.$emit('video_pause')
   },
   feedback_reset(){
      lv['open_' + 1] = false
      lv['open_' + 2] = false
      lv['open_' + 3] = false
      lv['open_' + 4] = false
      lv.targ = '.feedback_content'
      gsap.set(lv.targ,{display: 'none'})
      lv.targ = '.icon_info'
      gsap.set(lv.targ,{rotation:'0deg'})
   },
   feedback_over(id){
     return
      lv.targ = '#feedback_' + id + ' .icon_info'
      gsap.to(lv.targ,{duration:0.3, scale:1.2, ease:'back.inout'})
   },
   feedback_out(id){
     return
      lv.targ = '#feedback_' + id + ' .icon_info'
      gsap.to(lv.targ,{duration:0.3, scale:1, ease:'back.inout'})
   }
  }
}



</script>

<style>

  .pad-fb{
    padding: 30px 30px 35px 30px;
  }
  .mb-fb{
    margin-bottom: 48px;
  }
  .wrap-fb-content{
    position: relative;
    top: -33px;
  }
  .wrap-fb-content-media{
    position: relative;
    top: 33px;
  }
  .miframe{
    display: block;
    height: 100%;
    width: 100%;
  }
</style>