<template>
  <div class="mode-center">

    <div id="kit_intro">
      <kit_intro />
    </div>

    <div class="hidden" id="kit_step">
      <kit_step :obj="kit_step_obj" />
    </div>

    <div class="hidden" id="kit_outro">
      <kit_outro />
    </div>

    <div class="pos-g hidden opacity-0 bg-dark" id="curtain"></div>


  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'
import control_main from '@/modules/control_main'

import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

import kit_intro from '@/components/kit_intro'
import kit_step from '@/components/kit_step'
import kit_outro from '@/components/kit_outro'

var lv = {}
lv.thisVue = null

lv.mode_prev = false
lv.feedback_categories = ['category_1','category_2','category_3','category_4']
lv.kit_item_fetch = null

export default {
  name: 'kit',
  components: {
    kit_intro,
    kit_step,
    kit_outro
  },
  data() {
    return {
      kit_step_obj: {},
      step_cur: 0
    }
  },
  mounted() {
    lv.thisVue = this
  },
  methods: {
    flow_start(){
      lv.mode_prev = false
      this.kit_transit('first')
    },
    flow_restart(){
      globals.kit_history = [{id:0, chosen_nr:0, dest_id:0, has_feedback:false}]
      lv.targ = '#kit_intro'
      gsap.set(lv.targ,{display: 'block'})
      lv.targ = '#kit_step'
      gsap.set(lv.targ,{display: 'none'})
      lv.targ = '#kit_outro'
      gsap.set(lv.targ,{display: 'none'})
    },
    curtain_close(){
      lv.targ = '#curtain'
      gsap.to(lv.targ,{duration:0.6, opacity:1, display: 'block', ease:'power3.inOut'})
      lv.targ = '#step_next_button'
      gsap.to(lv.targ,{duration:0.6, opacity:0, display: 'none', ease:'power3.inOut'})
      gsap.to(window, {delay: 0.5, duration: 0.1, scrollTo: {y: 0}, ease:'power3.inOut'})
    },
    curtain_open(){
      lv.targ = '#curtain'
      gsap.to(lv.targ,{duration:0.6, opacity:0, display: 'none', ease:'power3.inOut'})
      lv.targ = '#step_next_button'
      gsap.to(lv.targ,{duration:0.6, opacity:1, display: 'block', ease:'power3.inOut'})
    },
    kit_transit(dest){
      this.curtain_close()
      gsap.delayedCall(0.6,this.kit_fetch_step_data, [dest])
    },
    kit_fetch_step_data(kit_item){
      lv.kit_item_fetch = kit_item
      lv.dataTask = 'kit_item_fetch'
      lv.dataEvent = 'data_kit_item_detail'
      api.call({
        task: lv.dataTask,
        event: lv.dataEvent,
        item: kit_item,
        global: true
      })
    },
    kit_step_populate(kit_item){
      this.kit_step_obj = globals.api_data['kit_item_' + kit_item]
      if(!lv.mode_prev){
        EventBus.$emit('kit_flow_feedback_close_instant')
        globals.kit_history.push({id:kit_item, chosen_nr:null, dest_id:null, has_feedback:false})
      }
      if(globals.kit_history[globals.kit_history.length - 1].has_feedback){
        EventBus.$emit('kit_flow_feedback_chosen', globals.kit_history[globals.kit_history.length - 1].chosen_nr)
        EventBus.$emit('kit_flow_feedback_show_instant')
      }
      if(globals.api_data['kit_item_' + kit_item].identify === '**end**'){
        kit_item = 'end'
      }
      this.kit_step_nav(kit_item)
    },
    kit_step_nav(dest){
      if(dest === 0){
        lv.targ = '#kit_intro'
        gsap.set(lv.targ,{display: 'block'})
        lv.targ = '#kit_step'
        gsap.set(lv.targ,{display: 'none'})
      }
      if(dest !== 0){
        lv.targ = '#kit_intro'
        gsap.set(lv.targ,{display: 'none'})
        lv.targ = '#kit_step'
        gsap.set(lv.targ,{display: 'block'})
        lv.targ = '#kit_outro'
        gsap.set(lv.targ,{display: 'none'})
      }
      if(dest === 'end'){
        lv.targ = '#kit_step'
        gsap.set(lv.targ,{display: 'none'})
        lv.targ = '#kit_outro'
        gsap.set(lv.targ,{display: 'block'})
      }
      this.step_cur = dest
      control_main.console_log(globals.kit_history)
      this.curtain_open()
    },
  }
}

EventBus.$on('kit_flow_start', (e) => {
  lv.thisVue.flow_start()
})
EventBus.$on('kit_flow_next', (e) => {
  lv.mode_prev = false
  lv.thisVue.kit_transit(globals.kit_history[globals.kit_history.length - 1].dest_id)
  EventBus.$emit('video_pause')
})
EventBus.$on('kit_flow_prev', (e) => {
  globals.kit_history.pop()
  lv.mode_prev = true
  lv.thisVue.kit_transit(globals.kit_history[globals.kit_history.length - 1].id)
  EventBus.$emit('video_pause')
})
EventBus.$on('kit_flow_choose', (e) => {
  globals.answer_text_cur = e.src['button_text_' + e.nr]
  globals.kit_history[globals.kit_history.length - 1].chosen_nr = e.nr
  globals.kit_history[globals.kit_history.length - 1].dest_id = e.dest_id
  lv.has_feedback = has_feedback(globals.kit_history[globals.kit_history.length - 1].id, e.nr)
  globals.kit_history[globals.kit_history.length - 1].has_feedback = lv.has_feedback
  if(lv.has_feedback){
    EventBus.$emit('kit_flow_feedback_chosen', e.nr)
    EventBus.$emit('kit_flow_feedback_show')
  }
  if(!lv.has_feedback){
    EventBus.$emit('kit_flow_next')
  }
  control_main.console_log(globals.kit_history)
})
EventBus.$on('data_kit_item_detail', (e) => {
  lv.thisVue.kit_step_populate(lv.kit_item_fetch)
})
EventBus.$on('kit_flow_restart', (e) => {
  lv.thisVue.flow_restart()
})

function has_feedback(item, feedback_nr){

  lv.avail = false

  lv.feedback_object = 'feedback_' + feedback_nr

  if(globals.api_data['kit_item_' + item].feedback[lv.feedback_object]){

    console.log('relevant feedback data ......................... ')
    console.log(globals.api_data['kit_item_' + item].feedback[lv.feedback_object])

    if(globals.api_data['kit_item_' + item].feedback[lv.feedback_object].item_1){
      lv.avail = true
    }
    if(globals.api_data['kit_item_' + item].feedback[lv.feedback_object].item_2){
      lv.avail = true
    }
    if(globals.api_data['kit_item_' + item].feedback[lv.feedback_object].item_3){
      lv.avail = true
    }
    if(globals.api_data['kit_item_' + item].feedback[lv.feedback_object].item_4){
      lv.avail = true
    }

  }


  return lv.avail
}

</script> 

<style>

</style>