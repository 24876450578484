<template>
  <div class="mode-center">
 
    <div class="pt63"></div>

    <div class="pblock">
      <div class="grid grid-cols-12 gap-10 mx900:grid-cols-1">
        <div class="text text-grey-light" :class="[!data_intro.media_type ? 'mn900:col-span-12' : 'mn900:col-span-7']">
          <div class="hdr1 pbhdr1">
            {{data_intro.title}}
          </div>
          <div class="wysiwyg text text-bread maxw560" v-html="data_intro.text">
          </div>
        </div>
        <div class="mn900:col-span-5 text-right relative mx900:text-center" v-if="data_intro.media_type">
          <img class="inline-block" :src="data_intro.image.file" />
        </div>
      </div>

      <div class="vspace2"></div>

      <div v-for="(item, index) in data" :key="index" :id="item.slug">
        <div class="relative rounded bg-malibu-500 psegment text text-grey-light" :style="{ backgroundColor: item.color }">
          <div class="relative">
              <div class="grid grid-cols-4 gap-10">
                <div class="hdrinfo select-none col-span-3">
                  {{item.title}}
                </div>
                <div class="text-right col-span-1">
                  <svg class="w-16 h-16 mx640:w-12 mx640:h-12 inline-block icon_info" style="fill:#f4f4f4" viewBox="0 0 16.933333 16.933333"><g transform="translate(0,-280.06669)"><path d="M 32 2 C 15.455147 2 2 15.455156 2 32 C 2 48.544882 15.455147 62 32 62 C 48.544852 62 62 48.544882 62 32 C 62 15.455156 48.544852 2 32 2 z M 32 6 C 46.383091 6 58 17.616932 58 32 C 58 46.383106 46.383091 57.998047 32 57.998047 C 17.616907 57.998047 6.0019531 46.383106 6.0019531 32 C 6.0019531 17.616932 17.616907 6 32 6 z M 31.970703 17.974609 A 2.0002 2.0002 0 0 0 30 19.996094 L 30 29.998047 L 19.998047 29.998047 A 2.0004851 2.0004851 0 1 0 19.998047 34 L 30 34 L 30 44.001953 A 2.0004923 2.0004923 0 1 0 34.001953 44.001953 L 34.001953 34 L 44.003906 34 A 2.0004851 2.0004851 0 1 0 44.003906 29.998047 L 34.001953 29.998047 L 34.001953 19.996094 A 2.0002 2.0002 0 0 0 31.970703 17.974609 z " transform="matrix(0.26458333,0,0,0.26458333,0,280.06669)"/></g></svg>
                </div>
              </div>
              <div class="pos-g cursor-pointer" @click="info_toggle(item.slug)" @mouseover="info_over(item.slug)" @mouseleave="info_out(item.slug)"></div>
          </div>
          <div class="hidden iblock_section pt-12">
            <div class="grid gap-10 grid-cols-12 mx900:grid-cols-1">
              <div class="text-bread col-span-7 mx900:col-span-1" :class="[!item.media_type ? 'col-span-12' : '']">
                <div class="wysiwyg" v-html="item.text">
                </div>
              </div>
              <div class="col-span-5 mx900:col-span-1 text-right mx900:text-center relative" v-if="item.media_type == 'image'">
                <img class="inline-block" :src="item.image.file" />
              </div>
              <div class="col-span-5 mx900:col-span-1 text-right relative" v-if="item.media_type == 'youtube' || item.media_type == 'vimeo'">
                <div class="w-full">

                  <video_plyr :item="item" :id="'info_' + index" />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-12"></div>
      </div>

    </div>

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import video_plyr from '@/components/video_plyr'

import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)



var lv = {}
lv.thisVue = null

export default {
  name: 'info',
  components: {
    video_plyr
  },
  data() {
    return {
      data_intro:{
        image: {}
      },
      data:{},
      youtube_pre: globals.embed.youtube.pre,
      youtube_post: globals.embed.youtube.post,
      vimeo_pre: globals.embed.vimeo.pre,
      vimeo_post: globals.embed.vimeo.post,
    }
  },
  mounted() {
    lv.thisVue = this

    lv.dataTask = "inzicht_fetch"
    lv.dataEvent = "data_inzicht_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      lv.thisVue.populate()
    })
  },
  methods: {
    populate(){
      this.data_intro = globals.api_data[lv.dataTask].item
      this.data = globals.api_data[lv.dataTask].items

      //this.scroll()

      this.$nextTick(function () {
        this.scroll()
      })


    },
    info_toggle(id){
      if(lv['open_' + id]){
        this.info_close(id)
      }else{
        this.info_open(id)
      }
    },
    info_open(id){
      lv.targ = '#' + id + ' .iblock_section'
      gsap.set(lv.targ,{display: 'block'})
      lv.targ = '#' + id + ' .icon_info'
      gsap.to(lv.targ,{duration:0.3, rotation:'45deg', ease:'back'})
      lv.desty = document.getElementById(id).offsetTop
      gsap.to(window, {duration: 0.5, scrollTo: {y: lv.desty, offsetY: 20}, ease:'power3.inOut'})
      lv['open_' + id] = true
    },
    info_close(id){
      lv.targ = '#' + id + ' .iblock_section'
      gsap.set(lv.targ,{display: 'none'})
      lv.targ = '#' + id + ' .icon_info'
      gsap.to(lv.targ,{duration:0.3, rotation:'0deg', ease:'back'})
      lv['open_' + id] = false
      
      EventBus.$emit('video_pause')
    },
    info_over(id){
      return
        lv.targ = '#' + id + ' .icon_info'
        gsap.to(lv.targ,{duration:0.3, scale:1.2, ease:'back.inout'})
    },
    info_out(id){
      return
        lv.targ = '#' + id + ' .icon_info'
        gsap.to(lv.targ,{duration:0.3, scale:1, ease:'back.inout'})
    },
    scroll(){
      if(this.$route.params.anchor){
        this.info_open(this.$route.params.anchor)
        gsap.killTweensOf(window)
        gsap.to(window, {delay:0.5, duration: 0.5 , scrollTo: {y: '#' + this.$route.params.anchor, offsetY: 20}, ease:'power4.inOut'})
      }
    },
    scroll_instant(){
      if(this.$route.params.anchor){
        gsap.killTweensOf(window)
        gsap.to(window, {duration: 0.8 , scrollTo: {y: '#' + this.$route.params.anchor, offsetY: 20}, ease:'power4.inOut'})
      }
    },
    scroll_native(did){
      gsap.killTweensOf(window)
      gsap.to(window, {duration: 0.8 , scrollTo: {y: '#' + did, offsetY: 20}, ease:'power4.inOut'})
    },

  },
  watch:{
      $route (to, from){
          //console.log(from + ' >>> ' + to)
          this.scroll_instant()
      }
  }
}
</script>

<style>

</style>