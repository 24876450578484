<template>

  <div class="relative" id="kit_step_wrap">

    <div class="question_wrap">

      <div class="text text-white text-big cursor-pointer navprev_nudge" @click="btnPrev">
        <div class="hover:text-soft-blue hover:border-soft-blue py-2 px-1 transition duration-300 ease-in-out flex items-center">
          <div class="inline-block mr-3">
            <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
          </div>
          Een stapje terug
        </div>
      </div>

      <div class="pt63 presp"></div>

      <div class="pblock">
        <div class="grid grid-cols-12 gap-10 mx900:grid-cols-1">
          <div class="text text-grey-light" :class="[!obj.image ? 'mn900:col-span-12' : 'mn900:col-span-7']">
            <div class="hdr1 pbhdr1">
              {{obj.title}}
            </div>
            <div class="wysiwyg text text-bread maxw560" v-html="obj.text">
            </div>
          </div>
          <div class="mn900:col-span-5 text-right relative mx900:text-center" v-if="obj.image">
            <img class="inline-block" :src="obj.image.md" />
          </div>
        </div>

        <!--  
        -->
        <div class="vspace1"></div>
        <div class="vspace1"></div>

      </div>



      <div class="stickywrap pointer-events-none" id="step_next_button">
        <div class="pos-g grad pointer-events-none"></div>
        <div class="relative mode-center-alt pointer-events-auto">
          <div class="grid gap-4 mx480:grid-cols-1 mb-5" 
            :class="[ obj.button_text_1 ? 'grid-cols-1' : '', obj.button_text_1 && obj.button_text_2 ? 'grid-cols-2' : '', obj.button_text_1 && obj.button_text_2 && obj.button_text_3  ? 'grid-cols-3' : '']"
          >
            <btn_default :obj="{'text':obj.button_text_1,'wFull': true, 'mode': 'button'}" @btn-click="btnNext({nr: '1', src:obj, dest_id:obj.connect_1})" v-show="obj.button_text_1" />
            <btn_default :obj="{'text':obj.button_text_2,'wFull': true, 'mode': 'button'}" @btn-click="btnNext({nr: '2', src:obj, dest_id:obj.connect_2})" v-show="obj.button_text_2" />
            <btn_default :obj="{'text':obj.button_text_3,'wFull': true, 'mode': 'button'}" @btn-click="btnNext({nr: '3', src:obj, dest_id:obj.connect_3})" v-show="obj.button_text_3" />
          </div>
        </div>
      </div>

      

    </div>


    <div class="feedback_wrap hidden ">
      <kit_feedback :obj="obj" />
    </div>

  </div>

</template>

<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import gsap from 'gsap'

import btn_default from '@/components/ui/btn_default'
import kit_feedback from '@/components/kit_feedback'

var lv = {}
lv.thisVue = null

lv.cats = ['veiligheid','sociaal','juridisch']

export default {
  name: 'kit_start',
  props: ['obj'],
  components: {
    btn_default,
    kit_feedback
  },
  data() {
    return {
    }
  },
  mounted() {
    lv.thisVue = this
  },
  methods: {
    btnPrev(){
      EventBus.$emit('kit_flow_prev')
    },
    btnNext(o){
      EventBus.$emit('kit_flow_choose', o)
    },
    feedback_show(){
      EventBus.$emit('feedback_reset')
      lv.targ = '#kit_step_wrap .question_wrap'
      gsap.set(lv.targ,{opacity:0, display: 'none'})
      lv.targ = '#kit_step_wrap .feedback_wrap'
      gsap.set(lv.targ,{opacity:1, display: 'block'})
    },
    feedback_hide(){
      lv.targ = '#kit_step_wrap .question_wrap'
      gsap.set(lv.targ,{opacity:1, display: 'block'})
      lv.targ = '#kit_step_wrap .feedback_wrap'
      gsap.set(lv.targ,{opacity:0, display: 'none'})
    },
    feedback_hide_instant(){
      lv.targ = '#kit_step_wrap .question_wrap'
      gsap.set(lv.targ,{opacity:1, display: 'block'})
      lv.targ = '#kit_step_wrap .feedback_wrap'
      gsap.set(lv.targ,{opacity:0, display: 'none'})
    },
    feedback_show_instant(){
      EventBus.$emit('feedback_reset')
      lv.targ = '#kit_step_wrap .question_wrap'
      gsap.set(lv.targ,{opacity:0, display: 'none'})
      lv.targ = '#kit_step_wrap .feedback_wrap'
      gsap.set(lv.targ,{opacity:1, display: 'block'})
    }
  }
}
EventBus.$on('kit_flow_feedback_show', (e) => {
  lv.thisVue.feedback_show()
})
EventBus.$on('kit_flow_feedback_close', (e) => {
  lv.thisVue.feedback_hide()
})
EventBus.$on('kit_flow_feedback_show_instant', (e) => {
  lv.thisVue.feedback_show_instant()
})
EventBus.$on('kit_flow_feedback_close_instant', (e) => {
  lv.thisVue.feedback_hide_instant()
})

</script>

<style>



</style>