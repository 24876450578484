<template>
  <div>

    <div class="plyr__video-embed js-player" v-if="item.media_type == 'youtube'" :id="'player_' + id">
      <iframe class="miframe"
        :src="'https://www.youtube.com/embed/' + item.youtube + '?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>

    <div class="plyr__video-embed js-player" v-if="item.media_type == 'vimeo'" :id="'player_' + id">
      <iframe class="miframe"
        :src="'https://player.vimeo.com/video/' + item.vimeo + '?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media'"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>


  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import Plyr from 'plyr';


export default {
  name: "video_plyr",
  props: ['item','id'],
  data() {
    return {
    }
  },
  mounted() {
    this.mid = this.$props.id
    this.$nextTick(function () {

      const player = new Plyr('#player_' + this.mid, {
        ratio: '16:9',
      })

      EventBus.$on('video_pause', (e) => {
        player.pause()
        control_main.console_log('pause video')
      })    


    })

  },
};
</script>

<style scoped>
</style>
